import React, {useContext} from 'react';
import classes from "../styles/components/submitwindow.module.scss";
import {LanguageContext} from "../App";


function SubmitWindow(props){

    const languageContext = useContext(LanguageContext);
    
    return(
        <div className={classes.layout}>
            <p>
                {props.typeText.text1}
                <span className={classes.orangeText}>{props.typeText.text2}</span>
                {props.typeText.text3}
                <br/>
                <br/>
                {"<1>"} - <span className={classes.greenText}>{props.name}</span>
                <br/>
                {"<2>"} - <span className={classes.greenText}>{props.money}АР</span>
                <br/>
                {"<3>"} - <span className={classes.greenText}>{props.days} {props.daysName}</span>
                <br/>
                {"<4>"} - <span className={classes.greenText}>+{props.percentage}%</span>
                <br/>
                <br/>
                <p>{languageContext.windowText.text20}<span className={classes.lightRedText}>{languageContext.windowText.text21}</span>{languageContext.windowText.text22}</p>
            </p>
            <button onClick={() => props.closeAlertWindow()} className={classes.submit_button}>{languageContext.windowText.text23}</button>
        </div>
    );
}

export default SubmitWindow;