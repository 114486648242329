export const languages = {
    en: {
        header: {
            login: "Login"
        },
        homePage: {
            fixedDescription: "Credit interest is counted for the whole period. If you pay the debt earlier than the deadline, you will need to pay the same fixed interest.",
            floatingDescription: "Credit interest is counted daily and you can pay debt within 60 days. Longer you pay the debt, higher the interest you will need to pay."
        },
        creditsPage: {
            noCreditsTitle: "You don't have credits :(",
            noCredits: "no credits :(",
            openCredits: "Open Credits",
            closedCredits: "Closed Credits"
        },
        credit: {
            billion: ' billion ',
            trillion: ' trillion ',
            quadrillion: ' quadrillion ',
            quintillion: ' quintillion ',
            sextillion: ' sextillion ',
            open: "Open",
            opened: "Opened",
            closed: "Closed",
            ending: "Ending",
            fixedCredit: "Fixed Credit",
            floatingCredit: "Floating Credit",
            pay: "Pay"
        },
        daysName:{
            initialName: "days"
        },
        title: "Online Credit",
        nickname: "Your Minecraft Nickname",
        discord: "Your Discord",
        fixedPercentage: "fixed",
        floatingPercentage: "floating",
        floatingUpToDate: "up to 60 days",
        time: "Time played on SP",
        weeks: "weeks",
        months: "months",
        moneyAmount: "AP amount",
        creditTerm: "Credit Term",
        totalPercentage: "Total Percentage",
        dailyPercentage: "Daily Percentage",
        apply: "Apply for a Credit",
        windowText: {
            text1: "To complete the credit you need to:",
            text2: "come to city",
            text3: "Paris",
            text4: "YB",
            text5: "find office",
            text6: "with turquoise carpets",
            text7: "take from",
            text8: "barrel \"Books to sign\" 2 books",
            text9: "In the book with conditions you need to change 4 inputs with following:",
            text10: "Example: \"I",
            text11: "applied for a credit\" -> \"I",
            text12: "applied for a credit\"",
            text13: "read the terms and condition and",
            text14: "sign book with your nickname",
            text15: "with any title (Credit/Agree/Document...)",
            text16: "if you wish you can make a copy of a document for yourself and",
            text17: "put original book in a barrel \"Signed Books\"",
            text18: "If all conditions are met and info is reliable APs will be transferred to your bank account soon. Also, the original book will have a time mark on it which will mean the beginning of a credit.",
            text19: "Good Game!",
            text20: "To apply for a credit write to ",
            text21: "maxirest",
            text22: " on discord and send a screenshot of this window.",
            text23: "Close",
            fixedText: {
                text1: "You have a ",
                text2: "fixed credit percentage",
                text3: " which means that you will need to pay it during the whole credit time no matter if you if you pay it earlier, this is a final percent.",
            },
            floatingText: {
                text1: "You have a ",
                text2: "floating credit percentage",
                text3: " which means that you will pay percents only for days since credit was taken."
            }
        }
    },
    ru: {
        header: {
            login: "Войти"
        },
        homePage: {
            fixedDescription: "Проценты по кредиту начисляются за весь период. Если вы погасите долг раньше установленного срока, вам нужно будет выплатить такой же фиксированный процент.",
            floatingDescription: "Проценты по кредиту начисляются ежедневно, а долг можно погасить в течение 60 дней. Чем больше времени вам понадобится, чтобы погасить свой долг, тем выше проценты, которые вам придется заплатить."
        },
        creditsPage: {
            noCreditsTitle: "У вас нет кредитов :(",
            noCredits: "нет кредитов :(",
            openCredits: "Открытые Кредиты",
            closedCredits: "Закрытые кредиты"

        },
        credit: {
            billion: ' миллиард ',
            trillion: ' триллион ',
            quadrillion: ' квадрильон ',
            quintillion: ' квинтильон ',
            sextillion: ' секстильон ',
            open: "Открытый",
            opened: "Открыт",
            closed: "Закрытый",
            ending: "Заканчивается",
            fixedCredit: "Фиксированный Кредит",
            floatingCredit: "Плавающий Кредит",
            pay: "Оплатить"
        },
        daysName:{
            initialName: "дней",
            versionOne: "дня",
            versionTwo: "дней"
        },
        title: "Онлайн Кредит",
        nickname: "Ваш ник в Майнкрафт",
        discord: "Ваш дискорд",
        fixedPercentage: "фиксированный",
        floatingPercentage: "плавающий",
        floatingUpToDate: "до 60 дней",
        time: "Время игры на СП",
        weeks: "недели",
        months: "месяца",
        moneyAmount: "Колличество АРов",
        creditTerm: "Срок Кредита",
        totalPercentage: "Итоговый Процент",
        dailyPercentage: "Дневной Процент",
        creditPercentage: "Процент Кредита",
        apply: "Оформить Кредит",
        windowText: {
            text1: "Для завершения оформления кредита вам нужно:",
            text2: "пройти в г.",
            text3: "Париж",
            text4: "ЖВ",
            text5: "найти офис",
            text6: "по бирюзовым коврам",
            text7: "взять из",
            text8: "бочки \"Книги для подписания\" 2 книги",
            text9: "В книге с условиями вам нужно заменить 4 значения cледующими:",
            text10: "Пример: \"Я",
            text11: "взял кредит\" -> \"Я",
            text12: "взял кредит\"",
            text13: "ознакомиться с условиями и",
            text14: "подписать книгу вашим ником",
            text15: "с любым названием (Кредит/Согласен/Договор...)",
            text16: "при желании вы можете сделать копию договора для себя и",
            text17: "положить оригинальную книгу в бочку \"Подписанные Книги\"",
            text18: "В случае выполнения всех условий и достоверности информации АРы будут переведены на ваш счет в ближайшее время. Так же на оригинальную книгу будет наложена временная метка, которая будет означать время начала действия договора.",
            text19: "Хорошей игры!",
            text20: "Для оформления кредита напишите в дс ",
            text21: "maxirest",
            text22: " и отправьте скрин этого окна.",
            text23: "Закрыть",
            fixedText: {
                text1: "У вас ",
                text2: "кредит с фиксированным процентом.",
                text3: " Это означает, что вам нужно будет оплатить его в течении всего кредитного периода, даже если кредит будет выплачен раньше.",
            },
            floatingText: {
                text1: "У вас ",
                text2: "кредит с плавающим процентом.",
                text3: " Это означает, что вам нужно будет оплатить проценты за прошедшие дни с момента взятия кредита."
            }
        }
    }
}