import {Col, Container, Row} from "react-bootstrap";
import logo from "../images/logo.PNG";
import classes from "../styles/components/header.module.scss";
import {NavLink} from "react-router-dom";
import {paths} from "../paths";
import {useContext, useEffect, useState} from "react";
import {LanguageContext} from "../App";

function Header(props) {

    const languageContext = useContext(LanguageContext);

    // const [user, setUser] = useState(null);
    //
    // const getMe = async () => {
    //     if(props.meFetched === false)
    //         await props.getMe();
    //     else
    //         setUser(props.me);
    // }
    //
    // useEffect(() => {
    //     getMe().then(() => {
    //         setUser(props.me);
    //     });
    // }, [props.meFetched]);
    //
    // const UserInfoArea = () => {
    //     if((user == null) || (user == "")){
    //         return(
    //             <a href={"http://localhost:8000/auth/discord/login"}><button className={classes.loginButton}>{languageContext.header.login}</button></a>
    //         );
    //     }
    //     else{
    //         return(
    //             <NavLink to={paths.creditsPage}>
    //                 <div className={classes.user}>
    //                     <h5>{user.name}</h5>
    //                     <img className={classes.userAvatar} src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png?size=256`}/>
    //                 </div>
    //             </NavLink>
    //         );
    //     }
    // }

    return (
        <Container fluid className={classes.header}>
            <Row>
                <Col>
                    <NavLink to={paths.homePage}>
                        <div className={classes.logo}>
                            <img src={logo}/>
                            <h5>SPCredit</h5>
                        </div>
                    </NavLink>
                </Col>
                <Col>
                    {/*<UserInfoArea/>*/}
                </Col>
            </Row>
        </Container>
    );
}

export default Header;