import {connect} from "react-redux";
import Header from "../../components/Header";
import {getMe, getMeFetched} from "../../store/selectors";
import {getMeThunkCreator} from "../../store/thunks";

let mapStateToProps = (state) => {
    return{
        me: getMe(state),
        meFetched: getMeFetched(state)
    }
}

let mapDispatchToProps = (dispatch) => {
    return{
        getMe: () => {
            dispatch(getMeThunkCreator());
        }
    }
}

const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header);

export default HeaderContainer;