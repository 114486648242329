import React, {useEffect} from "react";
import HeaderContainer from "../containers/HeaderContainer";

function Page({children}) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [children]);

    return(
        <>
            <HeaderContainer/>

            {children}
        </>
    )
}

export default Page;