export let initialState = {
    appStore: {
        meFetched: false,
        creditsFetched: false,
        me: null,
        credits: {
            open: null,
            closed: null
        }
    }
}