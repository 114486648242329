import {useContext, useEffect, useState} from "react";
import Credit from "../components/Credit";
import {LanguageContext} from "../App";
import {Col, Container, Row} from "react-bootstrap";
import classes from "../styles/components/credit.module.scss";

function CreditsPage(props) {

    const languageContext = useContext(LanguageContext);

    const [credits, setCredits] = useState({open: null, closed: null});

    async function getCredits() {
        if((props.creditsFetched === false) && (props.meFetched === true))
            await props.getCredits();
        else
            setCredits(props.credits);
    }

    useEffect(() => {
        getCredits().then(() => {
            setCredits(props.credits);
        });
    }, [props.creditsFetched, props.meFetched]);

    const CreditCol = ({credit}) => {
        return(
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <Credit type={credit.type} name={credit.name} money={credit.money} interest={credit.interest} days={credit.days} status={credit.status} time={credit.time} closedTime={credit.closedTime} paid={credit.paid}/>
            </Col>
        );
    }

    const CreditsArea = () => {

        if((credits == null) || (credits == "")){
            return(
                <p className={classes.creditTitle}>{languageContext.creditsPage.noCreditsTitle}</p>
            );
        }
        else{
            let listOpen = [];
            let listClosed = [];
            if(credits.open != null){
                listOpen = credits.open.map(credit => (
                    <CreditCol credit={credit}/>
                ));
            }
            else{
                listOpen.push(<p>{languageContext.creditsPage.noCredits}</p>)
            }

            if(credits.closed != null) {
                listClosed = credits.closed.map(credit => (
                    <CreditCol credit={credit}/>
                ));
            }
            else{
                listClosed.push(<p>{languageContext.creditsPage.noCredits}</p>)
            }

            return (
                <Row>
                    <h2 className={classes.creditTitle}>{languageContext.creditsPage.openCredits}</h2>
                    {listOpen}
                    <h2 className={classes.creditTitle}>{languageContext.creditsPage.closedCredits}</h2>
                    {listClosed}
                </Row>
            );
        }
    }

    return(
        <Container>
            <CreditsArea/>
        </Container>
    );
}

export default CreditsPage;