import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import logo from "../images/logo.PNG";
import classes from "../styles/pages/homepage.module.scss";
import SubmitWindow from "../components/SubmitWindow";
import axios from "axios";
import Header from "../components/Header";
import {LanguageContext} from "../App";
import { userLang } from '../scripts/userLang';
import { FaInfoCircle } from "react-icons/fa";

function HomePage() {

    const languageContext = useContext(LanguageContext);

    const initialPercentage = 1.5;
    const initialFloatingAddPercentage = 0.4;

    const FIXED = 'FIXED';
    const FLOATING = 'FLOATING';

    const nameInput = React.createRef();
    const discordInput = React.createRef();
    const moneyRange = React.createRef();
    const daysRange = React.createRef();
    const percentageLabel = React.createRef();
    const alertWindow = React.createRef();
    const moneyRangeDescription = React.createRef();
    const daysRangeDescription = React.createRef();
    const playTime1 = React.createRef();
    const playTime2 = React.createRef();
    const playTime3 = React.createRef();
    const playTime4 = React.createRef();

    const [typeHover, setTypeHover] = useState(null);
    const [name, setName] = useState("");
    const [discord, setDiscord] = useState("");
    const [playTime, setPlayTime] = useState(0);
    const [currentMoneyRange, setCurrentMoneyRange] = useState(0);
    const [currentDaysRange, setCurrentDaysRange] = useState(0);
    const [daysName, setDaysName] = useState(languageContext.daysName.initialName);
    const [creditPercentage, setCreditPercentage] = useState(initialPercentage);
    const [creditPercentageSubtraction_playTime, setCreditPercentageSubtraction_playTime] = useState(0);
    const [creditPercentageSubtraction_money, setCreditPercentageSubtraction_money] = useState(0);
    const [creditPercentageSubtraction_days, setCreditPercentageSubtraction_days] = useState(0);

    const fixContent = React.createRef();
    const floatContent = React.createRef();
    const [fixContentStatus, setFixContentStatus] = useState(true);

    useEffect(() => {

        let percentage;

        if(fixContentStatus){
            percentage = initialPercentage + creditPercentageSubtraction_playTime + creditPercentageSubtraction_money + creditPercentageSubtraction_days;
        }
        else{
            percentage = initialPercentage + creditPercentageSubtraction_playTime + creditPercentageSubtraction_money + initialFloatingAddPercentage;
        }

        percentage = Math.round((percentage) * 100) / 100;

        if(fixContentStatus){
            if(percentage >= 1.5)
                percentageLabel.current.setAttribute("color", "dark_red");
            if(percentage === 1.4)
                percentageLabel.current.setAttribute("color", "red");
            if(percentage === 1.3)
                percentageLabel.current.setAttribute("color", "light_red");
            if((percentage === 1.2) || (percentage === 1.1))
                percentageLabel.current.setAttribute("color", "orange");
            if(percentage === 1)
                percentageLabel.current.setAttribute("color", "yellow");
            if(percentage === 0.9)
                percentageLabel.current.setAttribute("color", "light_yellow");
            if(percentage === 0.8)
                percentageLabel.current.setAttribute("color", "green");
            if((percentage === 0.7) || (percentage <= 0.6))
                percentageLabel.current.setAttribute("color", "light_green");

            percentage *= currentDaysRange;
            percentage = Math.round((percentage) * 100) / 100;
        }

        else{
            if(percentage >= 1.9)
                percentageLabel.current.setAttribute("color", "dark_red");
            if(percentage === 1.8)
                percentageLabel.current.setAttribute("color", "red");
            if(percentage === 1.7)
                percentageLabel.current.setAttribute("color", "light_red");
            if(percentage === 1.6)
                percentageLabel.current.setAttribute("color", "orange");
            if(percentage === 1.5)
                percentageLabel.current.setAttribute("color", "yellow");
            if(percentage === 1.4)
                percentageLabel.current.setAttribute("color", "light_yellow");
            if(percentage === 1.3)
                percentageLabel.current.setAttribute("color", "green");
            if(percentage === 1.2)
                percentageLabel.current.setAttribute("color", "light_green");
        }

        setCreditPercentage(percentage);

    }, [creditPercentageSubtraction_playTime, creditPercentageSubtraction_money, creditPercentageSubtraction_days, fixContentStatus, currentDaysRange])

    const changeMoneyRange = () => {
        if((currentMoneyRange === 0) && (moneyRange.current.getAttribute("valid") === "false")){
            moneyRange.current.setAttribute("valid", "true");
            moneyRangeDescription.current.setAttribute("valid", "true");
        }

        const money = parseInt(moneyRange.current.value);
        setCurrentMoneyRange(money);

        if((money >= 300) && (money <= 600))
            setCreditPercentageSubtraction_money(-0.1);
        else if((money >= 601) && (money <= 1000))
            setCreditPercentageSubtraction_money(-0.2);
        else if(money >= 1001)
            setCreditPercentageSubtraction_money(-0.3);
        else
            setCreditPercentageSubtraction_money(0);
    }

    const changeDaysRange = () => {
        if((currentDaysRange === 0) && (daysRange.current.getAttribute("valid") === "false")){
            daysRange.current.setAttribute("valid", "true");
            daysRangeDescription.current.setAttribute("valid", "true");
        }

        const days = parseInt(daysRange.current.value);
        setCurrentDaysRange(days);

        if(userLang === "ru"){
            if((days % 10 === 1) && (days !== 11))
                setDaysName(languageContext.daysName.versionOne);
            else if(((days % 10 === 2) && (days !== 12)) || ((days % 10 === 3) && (days !== 13)) || ((days % 10 === 4) && (days !== 14)))
                setDaysName(languageContext.daysName.versionTwo);
            else
                setDaysName(languageContext.daysName.initialName);
        }

        if((days >= 11) && (days <= 30))
            setCreditPercentageSubtraction_days(-0.1);
        else if(days >= 31)
            setCreditPercentageSubtraction_days(-0.2);
        else
            setCreditPercentageSubtraction_days(0);
    }

    const changePlayTime = (num) => {
        if(creditPercentageSubtraction_playTime == 0){
            playTime1.current.setAttribute("valid", "true");
            playTime2.current.setAttribute("valid", "true");
            playTime3.current.setAttribute("valid", "true");
            playTime4.current.setAttribute("valid", "true");
        }

        if(num === 1){
            setCreditPercentageSubtraction_playTime(0);
            playTime1.current.setAttribute("status", "active");
            playTime2.current.setAttribute("status", "inactive");
            playTime3.current.setAttribute("status", "inactive");
            playTime4.current.setAttribute("status", "inactive");
        }
        else if(num === 2){
            setCreditPercentageSubtraction_playTime(-0.2);
            playTime1.current.setAttribute("status", "inactive");
            playTime2.current.setAttribute("status", "active");
            playTime3.current.setAttribute("status", "inactive");
            playTime4.current.setAttribute("status", "inactive");
        }
        else if(num === 3){
            setCreditPercentageSubtraction_playTime(-0.3);
            playTime1.current.setAttribute("status", "inactive");
            playTime2.current.setAttribute("status", "inactive");
            playTime3.current.setAttribute("status", "active");
            playTime4.current.setAttribute("status", "inactive");
        }
        else if(num === 4){
            setCreditPercentageSubtraction_playTime(-0.4);
            playTime1.current.setAttribute("status", "inactive");
            playTime2.current.setAttribute("status", "inactive");
            playTime3.current.setAttribute("status", "inactive");
            playTime4.current.setAttribute("status", "active");
        }

        setPlayTime(num);
    }

    const validAllInputs = () => {
        nameInput.current.setAttribute("valid", "true");
        discordInput.current.setAttribute("valid", "true");
        moneyRange.current.setAttribute("valid", "true");
        daysRange.current.setAttribute("valid", "true");
        playTime1.current.setAttribute("valid", "true");
        playTime2.current.setAttribute("valid", "true");
        playTime3.current.setAttribute("valid", "true");
        playTime4.current.setAttribute("valid", "true");
        moneyRangeDescription.current.setAttribute("valid", "true");
        daysRangeDescription.current.setAttribute("valid", "true");
    }

    const submitCredit = async () => {
        if((name !== "") && (discord !== "") && (playTime !== 0) && (currentMoneyRange !== 0) && ((currentDaysRange !== 0) || (fixContentStatus === false))){
            await logCredit();

            validAllInputs();

            alertWindow.current.setAttribute("status", "active");
        }
        else{
            validAllInputs();

            if((name === "") && (nameInput.current.getAttribute("valid") === "true"))
                nameInput.current.setAttribute("valid", "false");
            if((discord === "") && (discordInput.current.getAttribute("valid") === "true"))
                discordInput.current.setAttribute("valid", "false");
            if((playTime === 0) && (playTime1.current.getAttribute("valid") === "true")){
                playTime1.current.setAttribute("valid", "false");
                playTime2.current.setAttribute("valid", "false");
                playTime3.current.setAttribute("valid", "false");
                playTime4.current.setAttribute("valid", "false");
            }
            if((currentMoneyRange === 0) && (moneyRange.current.getAttribute("valid") === "true")){
                moneyRange.current.setAttribute("valid", "false");
                moneyRangeDescription.current.setAttribute("valid", "false");
            }
            if((currentDaysRange === 0) && (daysRange.current.getAttribute("valid") === "true")){
                daysRange.current.setAttribute("valid", "false");
                daysRangeDescription.current.setAttribute("valid", "false");
            }
        }
    }

    const closeAlertWindow = () => {
        if(alertWindow.current.getAttribute("status") === "active"){
            alertWindow.current.setAttribute("status", "inactive");
            window.location.reload();
        }
    }

    const logCredit = async () => {
        const logText = `${name} (ds: ${discord}), который играет #${playTime} оформил кредит на ${currentMoneyRange}АР на ${currentDaysRange} ${daysName} под ${creditPercentage}%`;

        axios.post(`https://spcredit.maxwebdev.tech/logcredit`, {
            "log": logText
        })
            .then(res => {
                console.log(res)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const changeName = (e) => {
        if((name === "") && (nameInput.current.getAttribute("valid") === "false"))
            nameInput.current.setAttribute("valid", "true");

        setName(e.target.value);
    }

    const changeDiscord = (e) => {
        if((discord === "") && (discordInput.current.getAttribute("valid") === "false"))
            discordInput.current.setAttribute("valid", "true");

        setDiscord(e.target.value);
    }

    function AlertWindow(){
        let typeText;
        let time;

        if(fixContentStatus === true){
            time = currentDaysRange;
            typeText = {...languageContext.windowText.fixedText};
        }

        else{
            time = 60;
            typeText = {...languageContext.windowText.floatingText};
        }

        return(
            <div className={classes.alert_window} ref={alertWindow} status={"inactive"}>
                <div className={classes.blurBackground}></div>
                <SubmitWindow name={name} money={currentMoneyRange} days={time} percentage={creditPercentage} typeText={typeText} daysName={daysName} closeAlertWindow={closeAlertWindow}/>
            </div>
        );
    }

    const selectActive = () => {
        if(fixContent.current.getAttribute("status") === "inactive") {
            fixContent.current.setAttribute("status", "active");
            floatContent.current.setAttribute("status", "inactive");
            setFixContentStatus(true);
        }
    }

    const selectHistory = () => {
        if(floatContent.current.getAttribute("status") === "inactive") {
            fixContent.current.setAttribute("status", "inactive");
            floatContent.current.setAttribute("status", "active");
            setFixContentStatus(false);
        }
    }

    const PercentageLabel = () => {
        if (fixContentStatus) {
            return (
                <h4>{languageContext.totalPercentage}</h4>
            );
        } else {
            return (
                <h4>{languageContext.dailyPercentage}</h4>
            );
        }
    }

    const CreditInfo = (props) => {
        if(props.type === FIXED){
            return(
                <p className={classes.infoText}>{languageContext.homePage.fixedDescription}</p>
            );
        }
        else if(props.type === FLOATING){
            return(
                <p className={classes.infoText}>{languageContext.homePage.floatingDescription}</p>
            );
        }
    }

    return(
        <>

            <AlertWindow/>

            <Container className={classes.credit_layout}>
                <h1>{languageContext.title}</h1>
                <hr className={classes.divider}/>
                <h4>{languageContext.nickname}</h4>
                <input onChange={e => changeName(e)} value={name} placeholder={"maxi_capybara"} className={classes.textInput} ref={nameInput} valid={"true"}/>
                <h4>{languageContext.discord}</h4>
                <input onChange={e => changeDiscord(e)} value={discord} placeholder={"maxirest"} className={classes.textInput} ref={discordInput} valid={"true"}/>

                <CreditInfo type={typeHover}/>

                <Row className={classes.creditTypes}>
                    <Col xl={3} lg={3} md={2} sm={1} xs={0}></Col>
                    <Col xl={3} lg={3} md={4} sm={5} xs={6}>
                        <p onClick={selectActive} ref={fixContent} status={"active"} className={classes.selectContent}>{languageContext.fixedPercentage} <FaInfoCircle className={classes.infoButton} onMouseEnter={() => setTypeHover(FIXED)} onMouseLeave={() => setTypeHover(null)}/></p>
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={5} xs={6}>
                        <p onClick={selectHistory} ref={floatContent} status={"inactive"} className={classes.selectContent}>{languageContext.floatingPercentage} <FaInfoCircle className={classes.infoButton} onMouseEnter={() => setTypeHover(FLOATING)} onMouseLeave={() => setTypeHover(null)}/></p>
                    </Col>
                    <Col xl={3} lg={3} md={2} sm={1} xs={0}></Col>
                </Row>

                <h4>{languageContext.time}</h4>
                <Row>
                    <Col xl={3} lg={3} md={6} sm={6} xs={6}>
                        <button onClick={() => {changePlayTime(1)}} status={"inactive"} ref={playTime1} className={classes.playTime_button} valid={"true"}>0-2 {languageContext.weeks}</button>
                    </Col>
                    <Col xl={3} lg={3} md={6} sm={6} xs={6}>
                        <button onClick={() => {changePlayTime(2)}} status={"inactive"} ref={playTime2} className={classes.playTime_button} valid={"true"}>2-4 {languageContext.weeks}</button>
                    </Col>
                    <Col xl={3} lg={3} md={6} sm={6} xs={6}>
                        <button onClick={() => {changePlayTime(3)}} status={"inactive"} ref={playTime3} className={classes.playTime_button} valid={"true"}>1-3 {languageContext.months}</button>
                    </Col>
                    <Col xl={3} lg={3} md={6} sm={6} xs={6}>
                        <button onClick={() => {changePlayTime(4)}} status={"inactive"} ref={playTime4} className={classes.playTime_button} valid={"true"}>3 {languageContext.months} +</button>
                    </Col>
                </Row>
                <h4>{languageContext.moneyAmount}</h4>
                <input ref={moneyRange} onChange={changeMoneyRange} type="range" min="100" max="2000" step="10" className={classes.rangeInput} valid={"true"}/>
                <p className={classes.rangeDescription} ref={moneyRangeDescription} valid={"true"}>{currentMoneyRange}AР</p>

                <h4>{languageContext.creditTerm}</h4>
                <input ref={daysRange} onChange={changeDaysRange} type="range" min="5" max="60" step="1" className={classes.rangeInput} valid={"true"} show={fixContentStatus.toString()}/>
                <p className={classes.rangeDescription} ref={daysRangeDescription} valid={"true"} show={fixContentStatus.toString()}>{currentDaysRange} {daysName}</p>
                <p className={classes.floatingUpToDate} show={(!fixContentStatus).toString()}>{languageContext.floatingUpToDate}</p>

                <PercentageLabel/>
                <h6 color={"dark_red"} ref={percentageLabel}>{creditPercentage}%</h6>

                <button onClick={submitCredit} className={classes.submit_button}>{languageContext.apply}</button>
            </Container>
        </>
    );
}

export default HomePage;