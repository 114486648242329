import classes from "../styles/components/credit.module.scss";
import {useContext} from "react";
import {LanguageContext} from "../App";
import { userLang } from '../scripts/userLang';
import memoize from 'memoize-one';

function Credit({ type, name, money, interest, days, status, time, closedTime, paid}){

    money = parseInt(money);
    interest = parseFloat(interest);
    days = parseInt(days);
    paid = parseInt(paid);

    const languageContext = useContext(LanguageContext);
    const FIXED = 'fixed';
    const FLOATING = 'floating';
    const OPEN = 'open';
    const CLOSED = 'closed';
    const RU = 'ru';

    const endingDate = () => {
        const ending = new Date(Date.parse(time) + ((days - 1) * 24 * 3600 * 1000));
        let month = ending.getMonth() + 1;
        if(month < 10){
            month = "0" + month;
        }
        let day = ending.getDate()
        if(day < 10){
            day = "0" + day;
        }
        let year = ending.getFullYear().toString().slice(-2);
        const endingDate = `${month}/${day}/${year}`;
        return endingDate;
    }

    const activeTime = () => {
        if(closedTime != undefined){
            const time1 = Date.parse(time);
            const time2 = Date.parse(closedTime);
            const sec = time2 - time1;
            const days = Math.floor(sec / (3600 * 24) / 1000);
            return days + 1;
        }
        else{
            const time1 = Date.parse(time);
            const current = new Date();
            const time2 = Date.parse(current);
            const sec = time2 - time1;
            const days = Math.floor(sec / (3600 * 24) / 1000);
            return days + 1;
        }
    }

    const countDebt = () => {
        let debt = money;
        if(type == FIXED){
            debt = countFixedDebt(activeTime());
        }
        else if(type === FLOATING){
            debt = countFloatingDebt(activeTime());
        }

        debt = Math.ceil(debt);

        return debt;
    }

    const displayDebt = () => {
        let debt = countedDebt();

        return checkBigNumber(debt);
    }

    const checkBigNumber = (debt) => {
        let BILLION = {number: 1000000000, name: languageContext.credit.billion};
        let TRILLION = {number: 1000000000000, name: languageContext.credit.trillion};
        let QUADRILLION = {number: 1000000000000000, name: languageContext.credit.quadrillion};
        let QUINTILLION = {number: 1000000000000000000, name: languageContext.credit.quintillion};
        let SEXTILLION = {number: 1000000000000000000000, name: languageContext.credit.sextillion};

        if(debt >= SEXTILLION.number){
            debt = (debt / SEXTILLION.number).toFixed(2) + SEXTILLION.name;
        }
        if(debt >= QUINTILLION.number){
            debt = (debt / QUINTILLION.number).toFixed(2) + QUINTILLION.name;
        }
        else if(debt >= QUADRILLION.number){
            debt = (debt / QUADRILLION.number).toFixed(2) + QUADRILLION.name;
        }
        else if(debt >= TRILLION.number){
            debt = (debt / TRILLION.number).toFixed(2) + TRILLION.name;
        }
        else if(debt >= BILLION.number){
            debt = (debt / BILLION.number).toFixed(2) + BILLION.name;
        }

        return debt;
    }

    const countFixedDebt = (activeTime) => {
        let totalDebt = money + (money * interest / 100);
        let timeLeft = activeTime - days;
        let dayInterest = interest / days;

        return countOverdue(timeLeft, dayInterest, totalDebt);
    }

    const countFloatingDebt = (activeTime) => {
        let totalDebt;
        let timeLeft = activeTime - days;
        let dayInterest = interest;

        if(activeTime <= days)
            totalDebt = money + (money * (interest * activeTime) / 100);
        else
            totalDebt = money + (money * (interest * days) / 100);

        return countOverdue(timeLeft, dayInterest, totalDebt);
    }

    const countOverdue = (timeLeft, dayInterest, totalDebt) => {
        if(timeLeft > 0){
            for(let i = 0; i < timeLeft; i++){
                if((i % 3) == 0){
                    dayInterest += 1;
                }
                if(i < 13){
                    totalDebt += money * dayInterest / 100;
                }
                else{
                    totalDebt += totalDebt * dayInterest / 100;
                }
            }
        }

        return totalDebt;
    }

    const showDays = () => {
        if(userLang === RU){
            if((days % 10 === 1) && (days !== 11))
                return languageContext.daysName.versionOne;
            else if(((days % 10 === 2) && (days !== 12)) || ((days % 10 === 3) && (days !== 13)) || ((days % 10 === 4) && (days !== 14)))
                return languageContext.daysName.versionTwo;
            else
                return languageContext.daysName.initialName;
        }
        else{
            return languageContext.daysName.initialName;
        }
    }

    const displayTime = (dateUS) => {
        if(userLang === RU){
            const parts = dateUS.split("/");
            const dateEU = `${parts[1]}/${parts[0]}/${parts[2]}`;

            return dateEU;
        }
        else{
            return dateUS;
        }
    }

    const countPaidPercent = () => {
        return Math.floor(paid / countedDebt() * 100) + "%";
    }

    const ShowType = () => {
        if(type === FIXED){
            return(
                <p>{languageContext.credit.fixedCredit}</p>
            );
        }
        else{
            return(
                <p>{languageContext.credit.floatingCredit}</p>
            );
        }
    }

    const ClosedTimeText = () => {
        if(closedTime != undefined){
            return(
                <div className={classes.fullWidth}>
                    <p className={classes.inline}>{languageContext.credit.closed}</p>
                    <p className={classes.inlineRight}>{displayTime(closedTime)}</p>
                </div>
            );
        }
        else{
            return (
                <div className={classes.fullWidth}>
                    <p className={classes.inline}>{languageContext.credit.ending}</p>
                    <p className={classes.inlineRight}>{displayTime(endingDate())}</p>
                </div>
            );
        }
    }

    const Status = () => {
        if(status === OPEN){
            return(
                <p className={classes.openStatus}>{languageContext.credit.open}</p>
            );
        }
        else if(status === CLOSED){
            return(
                <p className={classes.closedStatus}>{languageContext.credit.closed}</p>
            );
        }
    }

    const PayDebt = () => {
        if(status === OPEN){
            return(
                <div>
                    <div>
                        <input placeholder={"100"} className={classes.payInput}/>
                        <p className={classes.insideInput}>AP</p>
                    </div>
                    <button>{languageContext.credit.pay} className={classes.payButton}</button>
                </div>
            );
        }
    }

    const countedDebt = memoize(countDebt);
    const countedPaidPercent = memoize(countPaidPercent);

    return(
        <div key={name + time} className={classes.creditBlock}>
            <div className={classes.inline}>
                <b>{name}</b>
            </div>
            <div className={classes.inlineRight}>
                <p>{money}AP</p>
            </div>
            <div>
                <div className={`${"progress"} ${classes.progressBarBlock}`}>
                    <div className={`${"progress-bar"} ${classes.progressBar}`} style={{width: `${countedPaidPercent()}`}} role="progressbar" aria-valuenow={paid} aria-valuemin="0"
                         aria-valuemax={countedDebt()}>
                        {countedPaidPercent()}
                    </div>
                </div>
                <div className={classes.inline}>
                    <p>{paid}AP</p>
                    <br/>
                    <ShowType/>
                    <p>{interest}%</p>
                    <p>{days} {showDays}</p>
                </div>
                <div className={classes.inlineRight}>
                    <p className={classes.alignRight}>{displayDebt()}AP</p>
                    <br/>
                    <p className={classes.fullWidth}>{languageContext.credit.opened} <span className={classes.alignRight}>{displayTime(time)}</span></p>
                    {/*<div className={classes.fullWidth}>*/}
                    {/*    <p className={classes.inline}>Opened</p>*/}
                    {/*    <p className={classes.inlineRight}>{time}</p>*/}
                    {/*</div>*/}
                    <ClosedTimeText/>
                    <Status/>
                </div>
            </div>

            {/*<PayDebt/>*/}
        </div>
    );
}

export default Credit;