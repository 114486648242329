import {connect} from "react-redux";
import {getCredits, getCreditsFetched, getMeFetched} from "../../store/selectors";
import {getCreditsThunkCreator} from "../../store/thunks";
import CreditsPage from "../CreditsPage";

let mapStateToProps = (state) => {
    return{
        credits: getCredits(state),
        meFetched: getMeFetched(state),
        creditsFetched: getCreditsFetched(state)
    }
}

let mapDispatchToProps = (dispatch) => {
    return{
        getCredits: () => {
            dispatch(getCreditsThunkCreator());
        }
    }
}

const CreditsPageContainer = connect(mapStateToProps, mapDispatchToProps)(CreditsPage);

export default CreditsPageContainer;