import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/settings/app.scss';
import {Route, Routes} from "react-router-dom";
import {paths} from "./paths";
import HomePage from "./pages/HomePage";
import CreditsPage from "./pages/CreditsPage";
import {languages} from "./store/languages";
import Page from "./pages/schemaPages/Page";
import { userLang } from './scripts/userLang';
import CreditsPageContainer from "./pages/containers/CreditsPageContainer";

export const LanguageContext = React.createContext({});
function App() {

    let langText;
    if(userLang === "ru"){
        langText = languages.ru;
    }
    else{
        langText = languages.en;
    }

    return (
        <LanguageContext.Provider value={langText}>
            <Routes>
              <Route path={paths.homePage} element={<Page><HomePage/></Page>} />
              <Route path={paths.creditsPage} element={<Page><CreditsPageContainer/></Page>} />

              <Route path={paths.undefined} element={<Page><HomePage/></Page>} />
            </Routes>
        </LanguageContext.Provider>
    );
}

export default App;